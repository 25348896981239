@import "mixing.scss";
:root {
  font-size: 14px;
  background-color: #fff;
}
@mixin flexAlign {
  display: flex;
  align-items: center;
}
@mixin flexcontent {
  display: flex;
  justify-content: center;
}
@mixin flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexCenter {
  display: flex;

  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.flexcenterButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.height100 {
  min-height: 100%;
}
// .overlay {
//   display: none !important;
// }
#content {
  height: 100%;
}
.padding10 {
  padding: 10px 0;
}
.extra-bold {
  font-weight: 700 !important;
}
.coin_list {
  .MuiTypography-body2 span {
    font-size: 17px;
    font-weight: 600;
  }
  .MuiTypography-body1 span {
    font-size: 13px;
    font-weight: 300;
  }
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.alignstart {
    align-items: flex-start !important;
  }
  &.justify-end {
    justify-content: flex-end;
  }
}
.step_bar {
  height: 812px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  background-color: #302f35;
  // border-right: 1px solid #797979;

  backdrop-filter: blur(42px);
}
.completed,
.activeStep {
  h5 {
    width: 33px;
    height: 33px;
    background: #fff;
    position: absolute;
    z-index: 1;
    text-align: center;
    border-radius: 50%;
    left: -4px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.completed {
  height: 30px;
  width: 30px;
  border: 2px solid #ff2626;
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
}
.completed::before {
  content: " ";
  position: absolute;
  top: -229px;
  left: 50%;
  transform: translateX(-50%);
  height: 237px;
  width: 2px;
  background: #ff2626;
}
.activeStep {
  height: 30px;
  width: 30px;
  border: 2px solid rgb(13, 41, 19);
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  z-index: 999;
}
body {
  margin: 0;
  padding: 0;
  background: #fafafa;
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.left-nav {
  .MuiButton-label {
    display: flex;
    flex-direction: column;
  }
}
.page-heading {
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 54px;
}
.page-heading2 {
  font-weight: 400 !important;
  font-size: 30px !important;
}

.text-white {
  color: #fff;
}

.customForm {
  padding: 20px;
  padding-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #797979;

  &.MuiTextField-root {
    margin: 20px 0;
  }
  &h5 {
    margin: 10px 0;
  }
  // @media (max-width: 375px) {

  //   padding: 0px;

  // }
}

.rightButton {
  float: right;
}

.leftButton {
  float: left;
}

.dzu-dropzone {
  overflow: auto !important;
}
.rightPosition {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
.notication-list {
  a {
    color: #000;
  }
}
.width120 {
  width: calc(100% - 150px);
}
.ellispsys {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: pre;
  width: calc(100% - 30px);
}
@media screen and (max-width: 768px) {
  .slick-prev {
    left: -5px;
  }
  .step_bar {
    // height: 501px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .width120 {
    width: 100%;
    margin-bottom: 20px;
  }
  .rightPosition {
    position: absolute;
    top: auto;
    bottom: -5px;

    right: auto;
    left: 55px;
  }
}
.side_nev_Bottom {
  position: relative;
  bottom: 0;
  width: 100%;
}
.loginForm {
  width: 90%;
  max-width: 600px;
}
.fullwidth {
  width: 100%;
}
.faqBg {
  height: 150px;
  background: #e9c856;
  // background: url("https://dummyimage.com/600x400/00ab99/00ab99");
  border-radius: 12px;
}
.tradebox {
  background: #302f35;
  padding: 20px 40px 118px 40px;
  border-radius: 40px;
  margin-top: 40px;
  @media (max-width: 375px) {
    padding: 20px 0px 53px 26px;
    border-radius: 16px;
  }
}
.walletbox {
  padding: 10px;
}

.notificationbox {
  h5 {
    font-size: 20px;
    font-weight: 600;
    // color: #fff;
  }
}
.termcondition {
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
    color: #44edd6;
    line-height: 24px;
  }
  p {
    margin-bottom: 20px;
    line-height: 21px;
  }
}

.termcondition p {
  margin-bottom: 5px;
  line-height: 21px;
  color: #000;
}

.termcondition h4 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
label {
  margin-top: 15px;
  display: block;
  @media (max-width: 500px) {
    margin-top: 1px;
  }
}
.signupBox {
  @media (max-width: 1466px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.forgetbox {
  // background-color: #302F35;
  background: #000;
  color: #fff;
  a {
    color: #44edd6;
  }
}
// .signupmain{

//   padding: 20px 40px 80px 40px;
//   border-radius: 40px;
//   box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
//   background-color: #302F35;
//   backdrop-filter: blur(42px);
// }
// .bgforget{
//    padding: 20px 40px 80px 40px;
//   border-radius: 40px;
//   box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
//   background-color: #302F35;
//   backdrop-filter: blur(42px);
// }
.resetbox {
  background-color: #000;
}
.resethead {
  padding: 10px 40px 50px 40px;
  border-radius: 40px;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  background-color: #302f35;
  backdrop-filter: blur(42px);
  display: flex;
  justify-content: center;
  align-items: center;
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.alignstart {
    align-items: flex-start !important;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  @media (max-width: 768px) {
    padding: 10px 7px 50px 7px;
  }
}
.contactbox {
  h3 {
    font-size: 25px;
    font-weight: 600;
    // color: #1d2d3f;
  }
}
.emailbox {
  a {
    color: #fff;

    font-family: Roboto, sans-serif;
    text-decoration: none;
  }
}
.uploadbox {
  h6 {
    // color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}

.hexa {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -moz-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -ms-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.gon {
  width: 140px;
  height: 152px;
  background: #ffffff94;
  background-size: 220px 220px;
  line-height: 146px;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 2px;
  color: rgb(255, 25, 25);
  cursor: pointer;
  transition: color 500ms ease, text-shadow 500ms ease;
}

.hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: red;
  border-color: red;
  position: relative;
  display: inline-block;
}
.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}

.text-center {
  text-align: center !important;
}
a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
}

.justify-content-center {
  justify-content: center !important;
}
.BuyBox {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 70px;
}
.roadmap-wrap {
  position: relative;
  padding: 40px 0 10px;
  margin-bottom: 40px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-center {
  justify-content: center !important;
}
.roadmap-wrap {
  position: relative;
  padding: 40px 0 10px;
  margin-bottom: 40px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.roadmap-wrap:before,
.roadmap-wrap:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 7;
}

.roadmap-line {
  position: absolute;
  left: 19px;
  top: 0;
  background: #c1cef1;
  height: 100%;
  width: 2px;
  content: "";
  z-index: 9;
}
.roadmap-line:before {
  top: 0;
}

.roadmap-line:after,
.roadmap-line:before {
  position: absolute;
  background: #c1cef1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: "";
  left: -4px;
}

.roadmap-line:after {
  bottom: 0;
}

.roadmap-line:after,
.roadmap-line:before {
  position: absolute;
  background: #c1cef1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: "";
  left: -4px;
}
.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.roadmap-year {
  border-radius: 50%;
  color: #fff;
  background: #e41313;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial";
  font-size: 12px;
  position: relative;
  left: -5px;
}
.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}
.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.bg-light .roadmap-step {
  background: #fff;
}

.roadmap-step {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  position: relative;
  margin-left: 50px;
  padding: 20px 20px 15px;
  position: relative;
  max-width: 380px;
  @media (max-width: 768px) {
    border-radius: 16px;
  }
}
.bg-light .roadmap-step:before {
  border-color: #f6fafd;
}
.roadmap-step:before {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 5px solid #fff;
  background: #ff1919;
  left: -36px;
  top: 29px;
  z-index: 1;
}
.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.roadmap-head {
  margin-bottom: 10px;
  padding-right: 35px;
  position: relative;
}

.roadmap-time {
  display: block;
  color: #122272;
  font-size: 13px;
  text-transform: uppercase;
}

.roadmap-title {
  color: #8ca2e5;
  font-weight: 700;
  font-size: 16px;
}

.bg-light .roadmap-step:before {
  border-color: #f6fafd;
}

// .roadmap-finished .roadmap-head:after {
//   position: absolute;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 32px;
//   height: 32px;
//   line-height: 32px;
//   border-radius: 50%;
//   text-align: center;
//   color: #fff;
//   background: #23c99d;
//   font-family: "Font Awesome 5 Free";
//   font-weight: 700;
//   font-size: .88rem;
//   content: '\f00c';
// }

.roadmap-step p:last-child {
  margin-bottom: 0;
}
// p:last-child {
//   margin-bottom: 0;
// }

// p {
//   font-size: 14px;
//   margin-bottom: 1rem;
//   color: #415076;
// }

.roadmap-step:after {
  height: 2px;
  width: 22px;
  background: #dbe4f7;
  left: -22px;
  top: 42px;
}

.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.roadmap-wrap {
  padding: 40px 0 10px;
  margin-bottom: 60px;
  max-width: 100%;
}
.list-dot li {
  position: relative;
  padding-left: 22px;
  line-height: 1.62;
}
.list li {
  line-height: 1.44;
  padding: 5px 0;
  position: relative;
  color: #415076;
  font-size: 14px;
}

.list-dot li:before {
  position: absolute;
  top: 14px;
  left: 0;
  height: 5px;
  width: 5px;
  content: "";
  border-radius: 50%;
  background: #1940b0;
}

.navigation p {
  margin: 0 11px;
  font-size: 17px;
  padding: 4px 10px;
  cursor: pointer;
  // color: #000;
  font-weight: 300;
}

.roadmap-year {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 18px;
  position: static;
  margin-left: auto;
  margin-right: auto;
}
input:-webkit-autofill {
  // -webkit-text-fill-color: yellow !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #848484 !important;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  /* background-color: white; */
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
  background-color: black !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #000 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #000 !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list .country:hover {
  background-color: #000 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #000 !important;
}

.roadmap-right .roadmap-step {
  margin-left: 40px;
}

.roadmap-left {
  justify-content: flex-start;
  margin-left: 40px;
}

.roadmap-left .roadmap-step {
  margin-left: 0;
  margin-right: 0px;
}
.roadmap-left .roadmap-step:before {
  right: -36px;
}

.roadmap-head {
  margin-bottom: 15px;
}

.roadmap-step {
  width: auto;
  padding: 20px 30px 20px;
}
.roadmap-step:before {
  left: -36px;
}

.navigation p {
  margin: 0 5px;
  font-size: 17px;
  font-weight: 300;
  padding: 4px 10px;
  cursor: pointer;
  // color: #000 ;
}

.hovertext.active,
.hovertext:hover {
  color: #1069c2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.boxsmall2 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 100px;
  left: 30%;
  animation: moveOne 5s linear infinite;
}
.boxsmall4 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 143px;
  left: 40%;
  animation: moveOne 5s linear infinite;
}

.boxsmall3 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 218px;
  left: 35%;
  -webkit-animation: move 5s linear infinite;
  animation: move 5s linear infinite;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
input:-webkit-autofill {
  // -webkit-text-fill-color: yellow !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #848484 !important;
}
.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  max-height: 200px !important;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border: 2px dashed #d9d9d9;
  border-radius: 50% !important;
  height: 250px;
  @media (max-width: 600px) {
    max-width: 150px;
    max-height: 150px !important;
  }
}
.dzu-previewContainer {
  // padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 200px;
  z-index: 1;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
  padding: 0 !important;
}
.dzu-previewImage {
  // max-width: 164px;
  // border-radius: 25px !important;
  max-height: none !important;
  max-width: none !important;
  width: 100% !important;
  height: 250px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.receiveBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageanimationbanner {
  animation: imganimation1 5s infinite !important;
}

@keyframes imganimation1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.bannerBox {
  padding: 100px 0;
}
.termcondition h5 {
  font-size: 14px;
  font-weight: 600;
  color: #44edd6;
  line-height: 24px;
  margin-bottom: 6px;
  margin-top: 11px;
}

// .removescroll {
//   -ms-overflow-style: none;
// }

// ::-webkit-scrollbar {
//   display: none;
// }
.dzu-previewStatusContainer {
  display: none !important;
}

.loading {
  animation: spin 1s linear infinite both;
  animation-play-state: paused;
}
.loading:hover {
  animation-play-state: running;
}

.otp {
  width: 45px !important;
  height: 45px !important;
  text-align: center;
  margin-left: 10px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}
.uploadbox {
  h6 {
    // color: #fff;
    font-size: 12px;
    font-weight: 600;
  }
}
.v2_rodmap_card_sect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 52px;
}

.v2_roadmap_divider {
  position: absolute;
  bottom: -52px;
}
img {
  max-width: 100%;
  height: auto;
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_odd {
  padding: 30px 30px 0px 0px;
  margin-top: 80px;
  position: relative;
  width: 84%;
  margin-right: auto;
  @media (max-width: 920px) {
    width: 100%;
    margin-top: 0px;
    padding: 0px 0px 30px 0px;
  }
  .v2_roadmap_card {
    float: right;
    @media (max-width: 920px) {
      float: none;
    }
  }
}
.v2_roadmap_card {
  background: #000000;
  border: 2px solid #e9c856;
  border-radius: 27px;
  padding: 10px 40px 40px 40px;
  max-width: 320px;
  @media (max-width: 920px) {
    max-width: 100%;
  }
}
.v2_roadmap_card_sect.v2_roadmap_card_sect_even {
  padding: 30px 0px 0px 30px;
  margin-bottom: 80px;
  position: relative;
  width: 84%;
  margin-left: auto;
  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 0px;
    padding: 0px 0px 30px 0px;
  }
}
.v2_roadmap_divider {
  img {
    max-width: 135px;
  }

  @media (max-width: 920px) {
    display: none;
  }
}
.tv-embed-widget-wrapper__body {
  background: #fff !important;
  border: 1px solid #e0e3eb !important;
  border-radius: 3px !important;
  box-sizing: border-box !important;
  height: auto !important;
  overflow: hidden !important;
  position: relative !important;
}
.mainsec {
  .tv-embed-widget-wrapper__body.js-embed-widget-body {
    height: 100px !important;
  }
}
button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected {
  background: #302f35;
  color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// .activeClass,
//   .a:active{
// border-bottom: 2px solid !important;
// color: #e9c856 !important;
//   }
// }
// a.hovertext:active {

//   border-bottom: 2px solid !important;
//   color: #e9c856 !important;
// }
// }
.dzu-submitButton {
  display: none;
}
// .dzu-dropzone{
//   display: none;
// }

canvas.react-pdf__Page__canvas {
  width: 100% !important;
  // height: 400px !important;
}

.downBorder {
  position: relative;
  display: inline-block;
}

.downBorder:after {
  content: " ";
  width: 0;
  height: 0;
  left: 25%;
  bottom: 0;
  height: 15px;
  width: 50%;
  border-bottom: 3px solid #2d6ec5;
  position: absolute;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #1d2d3f !important;
  border-color: #1d2d3f !important;
}
.Unoique {
  animation: animName 3s linear infinite;
  width: 250px;
  height: 250px;
  // background: #0091ea;
  margin: 20px;
}

@keyframes animName {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.MuiPickersDay-daySelected:hover {
  background-color: #fff !important;
}
// .keyboardateclx {
// svg.MuiSvgIcon-root {
//    color: #fff;
// }
// }
.themeButton {
  position: fixed;
  bottom: 10px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 21px;
  padding: 1px 4px;
  left: 15px;
  svg {
    font-size: 16px;
  }
}
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  box-shadow: none;
}
.textFeilds {
  // background: #f3f2f9;
  border-radius: 10px !important;
  input {
    // padding: 16px 12px !important;
  }
  fieldset.PrivateNotchedOutline-root-65.MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.TabButtonsBox {
  // max-width: 700px;
  @media (max-width: 758px) {
    overflow-x: scroll !important;
    overflow: scroll;
    width: 100%;

    scroll-behavior: smooth;
    white-space: nowrap;
  }
}
.TabButtonsBox button.active {
  background-color: transparent;
  // background: #1eb808;
  border-bottom: 2px solid #fff;
  border-radius: 0px !important;
  padding: 10px 3px;
  color: #fff;
}

.TabButtonsBox button {
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 22px;
  border-radius: 5px;
  padding: 10px 3px;
  margin-right: 14px;
  color: rgb(245, 239, 239);
}

.P2PDashboard button.active {
  background-color: transparent;
  background: linear-gradient(180deg, #67d9ef 0%, #467ddb 100%);
  // border-bottom: 2px solid #fff;
  border-radius: 8px !important;
  padding: 11px 22px;
  color: #fff;
}

.P2PDashboard button {
  background-color: transparent;
  // background: linear-gradient(180deg, #67d9ef 0%, #467ddb 100%);
  border-bottom: 2px solid #fff;
  border: 1px solid #6ecff3;
  border-radius: 8px !important;
  padding: 11px 22px;
  color: #848484;
  color: rgb(9, 8, 8);
  @media (max-width: 920px) {
    margin-top: 10px;
  }
}

.sell button.active {
  background-color: transparent;
  background: linear-gradient(180deg, #67d9ef 0%, #467ddb 100%);
  // border-bottom: 2px solid #fff;
  border-radius: 8px !important;
  padding: 11px 22px;
  color: #fff;
}

.sell button {
  background-color: transparent;
  // background: linear-gradient(180deg, #67d9ef 0%, #467ddb 100%);
  border-bottom: 2px solid #fff;
  border: 1px solid #6ecff3;
  border-radius: 8px !important;
  padding: 11px 22px;
  color: #848484;
  // color: rgb(9, 8, 8);
  // @media (max-width: 920px) {
  //   margin-top: 10px;
  // }
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  border-radius: 15px;
  // background-color: #f3f5f6 !important;
}

.sellBtn {
  color: white;
  border-radius: 8px;
  padding: 9px 30px !important;
  font-size: 14px;
  // box-shadow: 0px 13px 27px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background-color: red;
}
.buyBtn {
  color: white;
  border-radius: 8px;
  padding: 9px 30px !important;
  font-size: 14px;
  // box-shadow: 0px 13px 27px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background-color: #3ecb81;
}
.backBlue {
  background: linear-gradient(
    180deg,
    rgba(0, 172, 235, 0.1) 0%,
    rgba(0, 176, 237, 0.1) 10.18%,
    rgba(16, 105, 194, 0.1) 70.35%,
    rgba(16, 105, 194, 0.1) 100%
  );
  border-radius: 10px;
  border: 1px solid #6ecff3;
  margin-top: 40px;
  padding: 40px;
}

.textFeilds input {
  // padding: 13.5px 14px !important;
}

svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: #7777;
}
svg.MuiSvgIcon-root.MuiStepIcon-root {
  color: #3a96dd;
}
.BuyButton {
  color: white !important;
  border-radius: 4px !important;
  padding: 10px 19px !important;
  margin: 5px !important;
  font-size: 14px !important;
  background: #3ecb81 !important;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%) !important;
}
.SellButton {
  color: white !important;
  border-radius: 4px !important;
  padding: 10px 19px !important;
  margin: 5px !important;
  font-size: 14px !important;
  background: red !important;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%) !important;
}

.containedButton {
  color: white;
  border-radius: 8px;
  padding: 5px 15px !important;
  margin: 5px;
  font-size: 14px;
  background: linear-gradient(
    180deg,
    #00aceb 0%,
    #00b0ed 10.18%,
    #1069c2 70.35%,
    #1069c2 100%
  );
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
}
.outlinedButton {
  color: #000 !important;
  border-radius: 8px;
  background: #fff;
  padding: 5px 15px !important;
  margin: 5px;
  font-size: 14px;
  border: 1px solid #3a444e !important;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 25%);
  button {
    color: #000;
  }
}

.Styleword {
  background: linear-gradient(
    180deg,
    #00aceb 0%,
    #00b0ed 10.18%,
    #1069c2 70.35%,
    #1069c2 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  line-height: "40px !important";
}

.TableContainerBox {
  border: 1px solid #6ecff3;
  border-radius: 10px;
}

span.MuiRating-icon.MuiRating-iconEmpty {
  color: #848484 !important;
}
span.MuiRating-icon.MuiRating-iconFilled {
  color: #eab73b !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-dayDisabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.moveTop {
  -webkit-animation: moveTop 5s normal linear infinite;
  animation: moveTop 5s normal linear infinite;
}

@keyframes moveTop {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

._4d\+fv {
  border-radius: 10px !important;
}

.X0jJn {
  padding: 6px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: rgba(25, 28, 31, 0.4);
  cursor: pointer;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom: 2px solid #f6f7f9;
  display: none !important;
}
.X0jJn.ec8oU {
  border-bottom: 2px solid var(--primary-color);

  color: var(--text-dark-color);
  display: block !important;
}
