.bannerBox {
  padding: 80px 0;
}
@mixin animationEffect {
  position: absolute;
  animation: move 2.5s linear infinite;
}
.imageanimationbanner {
  animation: imganimation1 5s infinite !important;
}
.tv-widget-watch-list__chart {
  height: 110px !important;
}
// .mainsecChart{
//   height: 150px !important;
// }
@keyframes imageanimationbanner {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.home-banner {
  // background-image: url("/images/banner.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 150px 0 80px;
  box-sizing: border-box;
  h1 {
    font-size: 50px;
    margin-bottom: 20px;
    font-weight: 700;
  }
}
.white-text {
  color: #fff;
}
.textleft {
  position: relative;
}
.circleBlur1 {
  position: absolute;
  max-width: 50px;
  right: 0%;
  bottom: 0%;
  animation: move 2.5s linear infinite;
}
.circleBlur6 {
  position: absolute;
  max-width: 50px;
  left: 0%;
  bottom: 0%;
  animation: move 2.5s linear infinite;
}
.circleBlur2 {
  position: absolute;
  max-width: 50px;
  right: 0%;
  top: 0%;
  animation: move 2.5s linear infinite;
}
.circleBlur3 {
  position: absolute;
  max-width: 50px;
  left: -60px;
  bottom: 0;
  animation: move 2.5s linear infinite;
}
.circle1 {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 100px;
  border-radius: 5px;
  animation: move 2.5s linear infinite;
}

.circle2 {
  position: absolute;
  left: 30%;
  top: 60%;
  width: 150px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: move 3s linear infinite;
}
.circle3 {
  position: absolute;
  right: 40px;
  top: 140px;
  width: 130px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: move 2.5s linear infinite;
}
.circle4 {
  position: absolute;
  left: 45%;
  top: 50px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: move 3.5s linear infinite;
}
.hoverimage {
  animation: move 3.5s linear infinite;
}
@-webkit-keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-moz-keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-o-keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(25px);
  }
  100% {
    transform: translateY(0px);
  }
}
.single-features {
  background-color: #000000;
  padding: 30px;
  position: relative;
  z-index: 1;
  @include hoverRedSlide;
}

.single-features::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  background-color: #e33224;
  z-index: -1;
}

.single-features h3 {
  color: #ffffff;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.timeline {
  height: 500px;

  .slide {
    position: relative;
    display: inline-block;
    list-style-type: none;
    width: 160px;
    height: 3px;
    background: #000;
    top: 200px;
    outline: none;
    &::before {
      width: 20px;
      height: 20px;
      background: #000;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-10px);
      content: "";
    }
    &::after {
      width: 3px;
      height: 80px;
      background: #000;
      position: absolute;
      top: -80px;
      left: 50%;
      transform: translateX(-2px);
      content: "";
    }
    .timeline-box {
      width: 100%;
      height: 100px;
      position: absolute;
      top: -180px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: #00e0b0;
      color: #fff;
      border-radius: 10px;
      padding: 20px;
      @include boxshadow;
      @include hoverRedSlide;
    }
    &:last-child {
      width: 280px;
    }
    &:not(:first-child) {
      margin-left: 0;
    }
  }
}
.slick-slide:first-child {
  height: 500px;
}
.slick-slide:nth-child(even) {
  .slide {
    position: relative;
    display: inline-block;
    list-style-type: none;
    width: 160px;
    height: 3px;
    background: #000;
    top: 200px;
    &::before {
      width: 20px;
      height: 20px;
      background: #000;
      border-radius: 50%;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-10px);
      content: "";
    }
    &::after {
      top: 0px;
    }
    .timeline-box {
      top: 80px;
    }
  }
}

.mainboxRoot {
  position: relative;
}
.circleEffetc1 {
  @include animationEffect();
  // top: 10%;
  bottom: 0%;
  max-width: 50px;
  left: 0%;
}
.circleEffetc2 {
  @include animationEffect();
  // top: 10%;
  top: -6%;
  right: 250px;
  max-width: 50px;
}
.circleEffetc3 {
  @include animationEffect();
  // top: 10%;
  top: 20%;
  left: 0px;
  max-width: 30px;
}
.circleEffetc4 {
  @include animationEffect();
  // top: 10%;
  top: 20%;
  right: 100px;
  max-width: 30px;
}
.circleEffetc5 {
  @include animationEffect();
  // top: 10%;
  left: 0%;
  bottom: 0px;
  max-width: 50px;
}

.slick-prev {
  left: -25px !important;
  @media (max-width: 1366px) {
    left: -7px !important;
  }
  @media (max-width: 375px) {
    left: -1px !important;
  }
}

.slick-next {
  right: -25px !important;
  @media (max-width: 1366px) {
    right: -7px !important;
  }
}
.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
  background: #ffffff !important;
  box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50%;
  top: 32% !important;
  z-index: 1;
  transform: translate(0, -22%) !important;
}

.slick-prev:before,
.slick-next:before {
  position: absolute;
  left: 14px;
  top: 5px;
}
.slick-next:before {
  content: url("arrow-right.svg") !important;
}
.slick-prev:before {
  content: url("arrow-left.svg") !important;
  left: 12px;
}
.icon-Box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.single-security {
  padding: 30px;
  @include boxshadow;
  @include hoverAnimation;
  .icon-Box {
    @include hoverAnimation;
  }
  @include hoverSlideUp;
  &:hover .icon-Box {
    @include hoverFlip;
  }
}
.approach-content {
  transition-delay: initial !important;
  @include hoverAnimation;
  @include hoverSlideUp;
  .icon-Box {
    @include hoverAnimation;
  }
  &:hover .icon-Box {
    @include hoverFlip;
  }
}

.team {
  @include hoverAnimation;
  @include hoverSlideUp;
  padding: 20px;
  text-align: center;
}
.headerNav {
  width: 100%;
  border-bottom: 10px solid #222;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px 0;
  height: 80px;
  background: #f8f8f8;
}
.logo a {
  padding-top: 33px;
  display: flex;
  flex-direction: column;
  clear: both;
  padding-bottom: 30px;
  text-decoration: none;

  p {
    width: 500px;
    display: block;
  }
  em {
    font-size: 0.5em;
    float: left;
    display: block;
    img {
      display: inline-block;
      margin-top: 5px;
      width: 15px;
      float: left;
    }
    .letterhead {
      display: inline-block;
      line-height: 260%;
      float: left;
    }
  }
}
.gray {
  color: #ccc;
}
a {
  color: #747474;
  opacity: 1;
  transition: all 0.6s;
  color: #747474;
  font-size: 1em;
}
a:hover {
  opacity: 1;
}
.fa-bars {
  display: none;
  color: #222;
  font-size: 2rem;
}
nav {
  ul {
    display: flex;
    justify-content: space-between;
  }
  li {
    margin: 0 15px;
    justify-content: space-between;
    font-size: 1em;
  }
  a {
    font-size: 1em;
    text-decoration: none;
    .active {
      color: tomato;
    }
  }
  a.active {
    color: #222;
  }
}

// @media only screen and (max-width: 800px) {

//   .logo {
//     padding-left: 15px;
//     padding-top: 0px !important;
//   }
// }
// @media only screen and (max-width: 600px) {
//   height: auto;
//   min-height: 50px;
//   display: block;
//   position: relative;
//   .logo {
//     width: 100%;
//     display: block;
//     padding-top: 20px;
//     margin: 0px;
//     margin-left: -5px;
//     a {
//       padding: 20px 0px;
//     }
//   }
// }

.footerHome {
  background-color: #000;
}
.text-center {
  text-align: center;
}
.justify-center {
  justify-content: center;
}
.navigation {
  p {
    margin: 0 5px;
    font-size: 17px;
    padding: 4px 10px;
    cursor: pointer;
    // color: #fff;
    font-weight: 300;
  }
  .loginButton {
    background-color: #00e0b0;
    color: #fff;
    padding: 4px 15px;
    border-radius: 4px;
  }
}

.deskmenu p {
  margin: 0 1px !important;
}

label.logo {
  color: white;
  font-size: 35px;
  line-height: 80px;

  font-weight: bold;
}
nav ul {
  float: right;
  // margin-right: 20px;
  padding: 0;
}
nav ul li {
  display: inline-block;
  // line-height: 80px;
  margin: 5px 0px;
}
nav ul li a {
  color: white;
  font-size: 17px;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
}
a.active,
a:hover {
  // background: #1b9bff;
  transition: 0.5s;
}
.checkbtn {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check {
  display: none;
}
@media (max-width: 952px) {
  label.logo {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 20px;
  }
  nav ul li a {
    font-size: 16px;
  }
}
.navbar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background: #000;
  height: 65px;
}
.sectionPadding {
  padding: 70px 0;
}

@media (max-width: 858px) {
  .sectionPadding {
    padding: 40px 0;
  }

  .home-banner {
    padding: 70px 0 70px;

    h1 {
      font-size: 35px;
      margin-bottom: 20px;
      font-weight: 700;
    }
    img {
    }
  }
  .navigation .loginButton {
    background-color: #f8fbfa;
    color: #00e0b0;
    padding: 4px 15px;
    border-radius: 4px;
    display: inline-block;
    min-width: 134px;
  }
  .navbar {
    position: relative;
    z-index: 999;
  }

  // .checkbtn {
  //   display: block;
  // }
  .navbar ul {
    position: absolute;
    width: calc(100% - 40px);
    height: auto;
    background: #000;
    display: block !important;
    margin: 0 !important;
    left: -108%;
    text-align: center;
    transition: all 0.5s;
    padding: 35px 0 !important;
    -webkit-box-shadow: 0 11px 24px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 11px 24px rgba(0, 0, 0, 0.12);
    box-shadow: 0 11px 24px rgba(0, 0, 0, 0.15);
  }
  nav ul li {
    display: block;
    margin: 10px 0 10px 5px;
    line-height: 30px;
  }
  nav ul li a {
    font-size: 20px;
  }
  a:hover,
  a.active {
    background: none;
    color: #0082e6;
  }
  #check:checked ~ ul {
    left: 10px;
    z-index: 99;
    height: 100vh !important;
    width: "100%";
  }
  .navigation p {
    // color: #000;
    width: 100%;
    display: flex;
    font-size: 16px;
    font-style: normal;
    align-items: flex-start;
    line-height: 24px;
    letter-spacing: -0.6px;
    justify-content: flex-start;
    padding: 8px 10px;
  }
}

.boxsmall2 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 100px;
  left: 30%;
  animation: moveOne 5s linear infinite;
}
.boxsmall4 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 143px;
  left: 40%;
  animation: moveOne 5s linear infinite;
}

.boxsmall3 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 218px;
  left: 35%;
  -webkit-animation: move 5s linear infinite;
  animation: move 5s linear infinite;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
nav ul {
  display: block;
  // display: flex;
  // flex-direction: column;
}
input:-webkit-autofill {
  // -webkit-text-fill-color: yellow !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: #848484 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  /* background-color: white; */
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
  background-color: rgb(163, 161, 161) !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #fff !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #fff !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list .country:hover {
  background-color: rgb(174, 168, 168) !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #000 !important;
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (max-width: 1219px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    // background: #f2f2f2;
    align-items: flex-start;
    padding-left: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1024px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
    width: 260px;
    height: 100%;
    display: flex;
    /* padding: 20px 0px; */
    // background: #f2f2f2;
    align-items: flex-start;
    padding-left: 10px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .roadmap-step {
    border: 1px solid transparent;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    position: relative;
    margin-left: 50px;
    padding: 20px 20px 15px;
    position: relative;
    max-width: 352px;
  }
}

@media (min-width: 992px) {
  .roadmap-left .roadmap-step:before {
    left: auto;
    right: -84px;
  }
  .roadmap-left .roadmap-step:after {
    left: auto;
    right: -74px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
    margin-left: 0;
  }
  .roadmap-right .roadmap-step {
    margin-left: 40px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-wrap {
    padding: 40px 0 10px;
    margin-bottom: 60px;
    max-width: 100%;
  }

  .roadmap-line {
    left: 50%;
    margin-left: -1px;
  }
  .roadmap {
    display: flex;
  }

  .roadmap-year {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 16px;
    position: static;
    margin-left: auto;
    margin-right: auto;
  }

  .roadmap-right {
    justify-content: flex-end;
  }

  .roadmap-right .roadmap-step {
    margin-left: 80px;
  }

  .roadmap-left {
    justify-content: flex-start;
    margin-top: -80px;
  }

  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left .roadmap-step:before {
    right: -84px;
  }

  .roadmap-head {
    margin-bottom: 15px;
  }

  .roadmap-step {
    width: 100%;
    padding: 40px 30px 35px;
  }
  .roadmap-step:before {
    left: -86px;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .roadmap-left .roadmap-step:after {
    right: -73px;
  }

  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }

  .roadmap-step:before {
    left: -84px;
  }

  .roadmap-left .roadmap-step:before {
    right: -84px;
  }
}

@media only screen and (max-width: 767px) {
  nav ul {
    float: left;
    margin-right: 20px;
    padding: 0;
  }

  .textcopy {
    width: calc(100% - 5px);
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }
}

#loading {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  // 100% {
  //   -webkit-transform: rotate(-360deg);
  //   -moz-transform: rotate(-360deg);
  //   -ms-transform: rotate(-360deg);
  //   transform: rotate(-360deg);
  // }
}
.MuiInput-underline:after {
  border-bottom: none;
}
.MuiInput-underline:before {
  border-bottom: none;
}
.starTwinkle {
  animation: twinkle 1s linear infinite;
}
@keyframes twinkle {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.5);
  }
}

* {
  -ms-overflow-style: none;
}
// ::-webkit-scrollbar {
//   display: none;
// }

.srollBoxNav {
  ::-webkit-scrollbar {
    display: none;
  }
}
